/* eslint-disable */
import { ChevronRight } from "lucide-react";
import AnimatedGradientText from "../../magicui/AnimatedGradientText";import { cn } from "../../../lib/utils";
;
export function AnimatedGradientTextDemo() {
    return (
      <div className="z-10 flex items-center justify-center">
        <AnimatedGradientText>
        🚀 <hr className="mx-2 h-4 w-px shrink-0 bg-gray-300" />
          <span
            className={cn(
              `inline animate-gradient bg-gradient-to-r from-[#1199FF] via-[#C0C0C0] to-[#1FA9FF] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent`,
            )}
          >
            Patent Protected
          </span>
          {/* <ChevronRight className="ml-1 size-3 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5" /> */}
        </AnimatedGradientText>
      </div>
    );
  }