/* eslint-disable */
import YouTube from 'react-youtube';
import React, { useEffect, useRef, useState } from 'react';
import { m, motion, useInView } from "framer-motion";
import { ChevronRight, Play } from "lucide-react";
import { cn } from "../../../lib/utils";
import { dispatch, useSelector } from '../../../redux/store';
import { getVideos } from '../../../redux/slices/media';
import { PATH_HOME } from '../../../routes/paths';
import { navConfig } from '../../../layouts/home/nav/config-navigation';
import { Dialog, Grid, IconButton, Stack } from '@mui/material';
import Iconify from '../../iconify';
import { varFade } from '../../animate';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { AnimatedGradientTextDemo } from '../AnimatedGradientTextDemo';
export function Hero() {
    const opts = {
    height: '400',
    width: '100%'
  };
  const fadeInRef = useRef(null);
  const fadeInInView = useInView(fadeInRef, { once: true });
  const [imageError, setImageError] = useState(false);

  const fadeUpVariants = {
    initial: { opacity: 0, y: 24 },
    animate: { opacity: 1, y: 0 },
  };

  const handleImageError = () => {
    console.error("Failed to load image: src/heroimg.png");
    setImageError(true);
  };

    const [videoOpen, setVideoOpen] = useState(false);

  const handleVideoClickOpen = () => {
    if(videoId){
      setVideoOpen(true);
    }
  };

  const handleVideoClose = () => {
    setVideoOpen(false);
  };

  const getYouTubeID = (url:any) => {
    const regExp = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url?.match(regExp);
    return match ? match[1] : '';
  };

  const [videoId, setVideoId] = useState(null);
  const videoData = useSelector((state:any) => state.media.video); 
  

  useEffect(() => {
    dispatch(getVideos() as any);
  }, [dispatch]);

  useEffect(() => {
    if (videoData) {
        setVideoId(videoData.marketingURL);
    }
}, [videoData]);
  console.log('videoData:',videoData, 'videoId:',videoId);

  return (
    <section id="hero" className="bg-gray-50 dark:bg-gray-900">
      <div className="relative h-full overflow-hidden py-6">
        <div className="container z-10 flex flex-col items-center">
          <div className="mt-2 flex flex-col items-center gap-4 text-center max-w-4xl mx-auto">
            <motion.h1
              ref={fadeInRef}
              className={cn(
                "text-balance bg-gradient-to-br from-black from-30% to-black/60 bg-clip-text py-2",
                "text-3xl font-bold leading-tight tracking-wide text-transparent", // Changed from font-bold to font-semibold
                "dark:from-white dark:to-white/40",
                "sm:text-4xl md:text-5xl lg:text-6xl"
              )}
              animate={fadeInInView ? "animate" : "initial"}
              variants={fadeUpVariants}
              initial={false}
              transition={{
                duration: 0.6,
                delay: 0.1,
                ease: [0.21, 0.47, 0.32, 0.98],
                type: "spring"
              } as any }
            >
              Your Best Chance to<br />Close Every Deal
            </motion.h1>

            <motion.p
              className="text-balance text-lg tracking-normal text-gray-600 dark:text-gray-300 md:text-xl lg:text-2xl max-w-2xl"
              animate={fadeInInView ? "animate" : "initial"}
              variants={fadeUpVariants}
              initial={false}
              transition={{
                duration: 0.6,
                delay: 0.2,
                ease: [0.21, 0.47, 0.32, 0.98],
                type: "spring"
              } as any}
            >
              Let your leads experience your fences and turn appointments into sales.
            </motion.p>
            <motion.div>
              <AnimatedGradientTextDemo />
            </motion.div>
            <motion.div
              animate={fadeInInView ? "animate" : "initial"}
              variants={fadeUpVariants}
              className="flex flex-col sm:flex-row gap-4"
              initial={false}
              transition={{
                duration: 0.6,
                delay: 0.3,
                ease: [0.21, 0.47, 0.32, 0.98],
                type: "spring"
              } as any}
            >
              <a
                     href={PATH_HOME.subscription}
                  data-nav-config={JSON.stringify(navConfig)} // Optional way to pass data
                className={cn(
                  "bg-[#1199ff] text-white shadow hover:bg-[#0088ee]",
                  "group relative inline-flex h-10 items-center justify-center gap-2 overflow-hidden whitespace-pre rounded-md px-5 py-2 text-base font-semibold tracking-tighter focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
                  "transform-gpu ring-offset-current transition-all duration-300 ease-out hover:ring-2 hover:ring-[#1199ff] hover:ring-offset-2",
                )}
              >
                Get Started
                <ChevronRight className="size-4 translate-x-0 transition-all duration-300 ease-out group-hover:translate-x-1" />
              </a>
              <a
               style={{cursor:'pointer'}}
                onClick={handleVideoClickOpen}
                className={cn(
                  "bg-white text-[#1199ff] border border-[#1199ff] shadow hover:bg-gray-50",
                  "group relative inline-flex h-10 items-center justify-center gap-2 overflow-hidden whitespace-pre rounded-md px-5 py-2 text-base font-semibold tracking-tighter focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
                  "transform-gpu ring-offset-current transition-all duration-300 ease-out hover:ring-2 hover:ring-[#1199ff] hover:ring-offset-2",
                )}
              >
                {videoId ? (
                  <>
                    Watch Video
                    <Play className="size-4" />
                  </>
                ):(
                  <>
                 
                  Watch Video <CircularProgress  size="1rem"/>
                </>
                )}
                
                
              </a>
             
            </motion.div>

            <motion.div
              animate={fadeInInView ? "animate" : "initial"}
              variants={fadeUpVariants}
              initial={false}
              transition={{
                duration: 1.4,
                delay: 0.4,
                ease: [0.21, 0.47, 0.32, 0.98],
                type: "spring"
              } as any}
              className="relative w-full mt-6"
            >
              {imageError ? (
                <div className="w-full h-48 bg-gray-300 flex items-center justify-center text-gray-600 rounded-xl">
                  Failed to load image
                </div>
              ) : (
                <img
                  src='assets/images/home/heroimg.png'
                  alt="RealityFence App Showcase"
                  className="w-full h-auto max-w-3xl mx-auto rounded-xl"
                />
              )}
            </motion.div>
          </div>
        </div>
      </div>
          <m.div variants={varFade().inUp}>
        <Dialog open={videoOpen} maxWidth="lg" fullWidth onClose={handleVideoClose}>
           <Stack sx={{ px: { lg: 4, xs: 1, md: 2 }, pt: 2, pb: 4 }}>
            <Grid item  sx={{ alignSelf: { xs: 'end', md: 'end' } }}>
               <IconButton color="inherit" edge="start" onClick={handleVideoClose}>
                 <Iconify icon="carbon:close-filled" sx={{ color: '1FA9FF' }} />
              </IconButton>
            </Grid>
             <YouTube videoId={getYouTubeID(videoId)} opts={opts} />
           </Stack>
        </Dialog>
       </m.div>
    </section>
  );
}

export default Hero;